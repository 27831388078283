<template>
  <!-- 历史模拟面试 -->
  <div id="interviewList">
    <div class="contains">
      <div
        class="interviews"
        v-for="(interview, index) in interviewList"
        :key="index"
      >
        <img :src="interview.avatar" alt="" />
        <span class="nickName">{{ interview.username }}</span>
        <span class="type">{{ interview.type }}</span>
        <div class="operateArea">
          <el-button
            v-if="interview.resume"
            type="primary"
            size="small"
            @click="readCv(interview.resume)"
            >查看简历</el-button
          >
        </div>
      </div>
    </div>

    <div style="padding: 20px; text-align: center">
      <el-pagination
        v-if="total > 20"
        :current-page.sync="currentPage"
        :total="total"
        layout="prev, pager, next"
        @current-change="getData"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getHistoryMockList } from "@/service/mock";
export default {
  name: "list",
  data() {
    return {
      interviewList: [],
      total: 0,
      currentPage: 1,
    };
  },
  methods: {
    getData(page) {
      if (page) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getHistoryMockList({ current: this.currentPage, size: 20 }).then(
        (res) => {
          if (res.success) {
            this.interviewList = res.result.records;
            this.total = res.result.total;
          }
        }
      );
    },
    readCv(resume) {
      window.open(resume);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
#interviewList {
  padding: 30px;
  .contains {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .interviews {
    width: 48%;
    background: #ffffff;
    border: 1px solid #eaeaea;
    padding: 16px 20px;
    line-height: 40px;
    margin-bottom: 20px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 10px;
    }
    .nickName,
    .type {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #34495e;
      margin-right: 20px;
    }
    .operateArea {
      float: right;
    }
  }
}
.accept {
  border: 1px solid #0a7aff;
  background: rgba(10, 122, 255, 0.1);
  width: 100px;
  color: #0a7aff;
}
</style>